import React from "react"

import Modal from "react-bootstrap/Modal"

import Price from "../price"
import Text from "../../text/text"

const UnitModal = ({ showModal, unit, handleCloseModal }) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{unit.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {unit.price && <Price price={unit.price} />}
        <div
          style={{
            paddingTop: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {unit.holds && (
            <div style={{ display: "inline-flex" }}>
              <Text weight="bold" style={{ marginRight: "5px" }}>
                Holds:
              </Text>
              <Text>{` ${unit.holds}`}</Text>
            </div>
          )}
          {unit.goodFor && (
            <div style={{ display: "inline-flex" }}>
              <Text weight="bold" style={{ marginRight: "5px" }}>
                Good for storing:
              </Text>
              <Text>{` ${unit.goodFor}`}</Text>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UnitModal
