import React, { useState } from "react"
import uniqid from "uniqid"

import UnitCard from "components/unit/card/card"
import PageLayout from "components/pageLayout"
import UnitModal from "components/unit/modal/modal"

import Row from "react-bootstrap/row"
import Col from "react-bootstrap/col"
import Container from "react-bootstrap/container"

import ratesStyles from "styles/rates.module.less"

import { useAllUnitsQuery } from "data/queries/units/unitQueries"

const Rates = () => {
  const data = useAllUnitsQuery()

  const [showModal, setShowModal] = useState(false)
  const [clickedUnit, setClickedUnit] = useState(null)

  const handleCloseModal = () => {
    setShowModal(false)
    setClickedUnit(null)
  }

  const handleShowModal = unit => {
    setClickedUnit(unit)
    setShowModal(true)
  }

  const units = data.allUnitsJson.edges.map(infoNode => {
    const photos = data.allFile.edges.filter(
      ({ node }) => infoNode.node.id === node.relativeDirectory
    )

    const unitNode = { ...infoNode.node, images: [...photos] }

    return unitNode
  })

  return (
    <PageLayout>
      <Container fluid className={ratesStyles.rates}>
        <Row>
          {units.map(unit => (
            <Col
              lg={4}
              md={4}
              sm={6}
              xs={12}
              key={uniqid()}
              className={ratesStyles.unitCol}
            >
              <UnitCard
                key={uniqid()}
                unit={unit}
                handleShowModal={handleShowModal}
              />
            </Col>
          ))}
        </Row>
        {showModal && (
          <UnitModal
            showModal={showModal}
            unit={clickedUnit}
            handleCloseModal={handleCloseModal}
          />
        )}
      </Container>
    </PageLayout>
  )
}
export default Rates
